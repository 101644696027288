import * as React from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import useIntersectionObserver from "../../particles/hooks/useIntersectionObserver";
import { SmP } from "../atoms/paragraph";
import { SmH2 } from "../atoms/headings";

// styles

const Wrapper = styled.div`
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

const Item = styled(motion.div)` 
  padding: 1rem;
  /* flex-basis: min(calc(640px - 2.5rem), 100%); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 680px) {
    padding: 0rem;
  }
`;

const variants = {
  hidden: {
    y: 100,
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.65,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 0.65,
    },
  },
};

interface FeatureProps {
  heading: string;
  body: string;
  // src: string;
}

const OverleafFeature: React.FunctionComponent<FeatureProps> = ({
  heading,
  body,

}) => {
  const { ref, visible } = useIntersectionObserver({
    root: null,
    rootMargin: "0px",
    threshold: 0.55,
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (visible === true) {
      controls.start("visible");
    }
    // visible ? controls.start("visible") : controls.start("hidden")
  }, [controls, visible]);

  return (
    <Wrapper ref={ref}>
      {/* <Container> */}
        {/* <Item>
         <img src={src} alt="Otter dancing with a fish" /> 
        </Item>  */}
        <Item animate={controls} variants={variants} initial="hidden">
          <div>
            <SmH2>{heading}</SmH2>
            <SmP>{body}</SmP>
          </div>
        </Item>
      {/* </Container> */}
    </Wrapper>
  );
};

export default OverleafFeature;
