import * as React from 'react';
import styled from 'styled-components';

import Video from '../../assets/final_test.mp4'

const HeroVideo = styled.video`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  height: auto;
`;

interface PromoVideoProps {
    
}
 
const PromoVideo: React.FunctionComponent<PromoVideoProps> = () => {
    return ( <HeroVideo controls muted><source src={Video} type="video/mp4" /> </HeroVideo>);
}
 
export default PromoVideo;