import * as React from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import useIntersectionObserver from "../../particles/hooks/useIntersectionObserver";

const Wrapper = styled.section`
  /* margin-bottom: 10rem; */
  text-align: center;
  /* padding: 0 1rem 5rem 1rem; */
`;

const Container = styled.div`
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  gap: 3rem;
`;

type CardType = {
  alignment: string;
};

const Card = styled(motion.div)<CardType>`
  border-radius: 100vw;
  border: 1px solid black;
  padding: 2.5rem 5rem;
  align-self: ${(props) => props.alignment};
  font-family: acumin-pro-wide,sans-serif;
  font-weight: 600;
  font-style: normal;
`;

const H2 = styled.h2`
  font-family: acumin-pro-wide,sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 0.75rem;
`;

const leftVariants = {
  hidden: {
    x: -50,
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.65,
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.3,
      ease: "easeOut",
      duration: 0.65,
    },
  },
};

const rightVariants = {
  hidden: {
    x: 50,
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.85,
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.3,
      ease: "easeOut",
      duration: 0.85,
    },
  },
};

interface FeedbackProps {
  feedback1?: string;
  feedback2?: string;
}

const Feedback: React.FunctionComponent<FeedbackProps> = ({
  feedback1,
  feedback2
}) => {
  const { ref, visible } = useIntersectionObserver({
    root: null,
    rootMargin: "0px",
    threshold: 0.55,
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (visible === true) {
      controls.start("visible");
    } else return;
  }, [controls, visible]);
  return (
    <Wrapper ref={ref}>
      <Container>
        <H2>What our users have to say:</H2>

        <Card
          alignment="flex-start"
          animate={controls}
          variants={leftVariants}
          initial="hidden"
        >
          {feedback1}
        </Card>
        <Card
          alignment="flex-end"
          animate={controls}
          variants={rightVariants}
          initial="hidden"
        >
         {feedback2}
        </Card>

      </Container>
    </Wrapper>
  );
};

export default Feedback;
