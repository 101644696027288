import * as React from "react";
import styled from "styled-components";
import OverleafFeature from "./feature";
import Testgrafik from "../../assets/test-grafik.png";
import { H2 } from "../atoms/headings";
import { HeroSubtitle, LargeP } from "../atoms/paragraph";

// import gif1 from '../../assets/overleafGifs/1.gif';
// import gif2 from '../../assets/overleafGifs/2.gif';
// import gif3 from '../../assets/overleafGifs/3.gif';
// import gif4 from '../../assets/overleafGifs/4.gif';

const Wrapper = styled.section`
  max-width: 1280px;
  margin: 0 auto;
`;

const BannerWrapper = styled.div`
  text-align: center;
  margin-bottom: 10.5rem;
  padding: 1rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 5rem;
  @media (min-width: 1280px) {
    justify-content: space-between;
  }
`;

const Item1 = styled.div`
  flex-basis: min(calc(512px - 5rem), 100%);
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @media (min-width: 680px) {
    padding: 0rem;
  }
`;

const Item2 = styled.div`
  flex-basis: min(768px, 100%);
  padding: 1rem;
  width: 100%;
  @media (min-width: 680px) {
    padding: 0rem;
  }
`;

interface OverleafFeaturesProps {}

const OverleafFeatures: React.FunctionComponent<OverleafFeaturesProps> = () => {
  return (
    <Wrapper>
      <BannerWrapper>
        <H2 style={{fontSize: "2.5rem"}}>Cite as you write</H2>
        <LargeP style={{ textAlign: "center", maxWidth: "100%" }}>
          BibTeX-empowered reference management for Overleaf and other modern
          LaTeX editors.
        </LargeP>
      </BannerWrapper>
      <Container>
        <Item1>
          <OverleafFeature
            heading="In-text citations, made dead simple."
            body="With the help of the CiteDrive Companion browser add-on, inserting in-text citations to your Overleaf paper is a snap. Simply place your cursor within the editor, click the “insert citation” button along the top of the screen, select the reference of interest and confirm."
          />
          <OverleafFeature
            heading="Collect references from anywhere"
            body="CiteDrive Companion improves life outside the editor as well by empowering you to quickly collect references where you find them. Whether you prefer to use PubMed, Google Scholar or other resources for discovering references, you can quickly generate, customize and add BibTeX entries to your project and immediately make them available for citation in your Overleaf paper."
          />
          <OverleafFeature
            heading="Natively Bib[La]TeX - not an afterthought."
            body="Most reference managers with BibTeX / BibLaTeX support do so as a conversion or translation and you lose out on much of the benefit and rich control offered by handcrafting your references. Because CiteDrive is built natively for Bib[La]TeX, you maintain full control and can leverage custom fields as you please. You shouldn’t be forced to choose between ease and utility."
          />
          <OverleafFeature
            heading="Collaborative, just like Overleaf"
            body="We believe collaborative writing and collaborative reference management go hand-in-hand. Add collaborators to your CiteDrive project just as you would with your Overleaf paper so collecting references can be a team effort."
          />
        </Item1>

        <Item2>
          <img src={Testgrafik} alt="test" />
        </Item2>
      </Container>{" "}
    </Wrapper>
  );
};

export default OverleafFeatures;
