import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: #fac188;
`

const Container = styled.div`
    padding: 5rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const H3 = styled.h3`
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 0.75rem;
`;

const Paragraph = styled.p`
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  color: #333333;
  margin-bottom: 1.25rem;
`

const CallToActionButton = styled.a`
display: block;
    padding: 1rem 2rem;
    background: #3fad92;
    border: none;
    color: white;
    border: none;
    border-radius: 6px;
    font-family: acumin-pro-wide, sans-serif;
  font-weight: 600;
  font-style: normal;
  width: 350px;
`

interface CallToActionPanelProps {
    heading: string;
    subheading: string;
    label: string;
}

// basic
 
const CallToActionPanel: React.FunctionComponent<CallToActionPanelProps> = ({
  heading,
  subheading,
  label,
}) => {
    return ( 
    <Wrapper>
        <Container>
            <H3>{heading}</H3>
            <Paragraph>{subheading}</Paragraph>
            <CallToActionButton href="https://app.citedrive.com/sign-up/">{label}</CallToActionButton>
        </Container>
    </Wrapper> );
}
 
export default CallToActionPanel;