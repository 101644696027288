import * as React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layouts/layout";
import OverleafHero from "../components/overleaf/hero";
import OverleafFeatures from "../components/overleaf/features";
import Feedback from "../components/overleaf/feedback";
import CallToActionPanel from "../components/overleaf/callToActionPanel";
import SEO from "../components/seo";
import { PrismicRichText } from "@prismicio/react";
import { H2 } from "../components/atoms/headings";
import { LargeP, P } from "../components/atoms/paragraph";
import { LI, OL } from "../components/atoms/lists";
import PromoVideo from "../components/atoms/video";
import { ExternalBlogPostLink, LinkBox } from "../components/atoms/links";

const Container = styled.div`
  margin-top: var(--top-margin);
  display: flex;
  flex-direction: column;
  gap: 5rem;
  @media (min-width: 1280px) {
    gap: 12.5rem;
  }
`;

const TextWrapper = styled.div`
  padding: 1rem;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 1280px) {
    padding: 0;
  }

  /* padding-left: 1rem;
  border-left: 10px solid #3fad92; */
`;

interface OverleafProps {}

// Sofware Application

const Overleaf = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicOverleaf;
  const { lang, type, url } = doc || {};
  const alternateLanguages = doc.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const seoTitle = doc.data.body.map((ele) => ele.primary.seo_title);
  const description = doc.data.body.map((ele) => ele.primary.meta_description);

  // hero
  const language = doc.lang;

  return (
    <Layout activeDocMeta={activeDoc}>
      <SEO
        lang={lang}
        description={description}
        title={seoTitle}
        type="webpage"
      />
      <Container>
        <OverleafHero
          eyebrow={doc.data.eyebrow.text}
          title={doc.data.title.richtext}
          subtitle={doc.data.subtitle.text}
        />

        <OverleafFeatures />
        <TextWrapper>
          <LargeP style={{ maxWidth: "100%", textAlign: "center" }}>
            At last, focus on authoring your paper instead of worrying about
            keeping your references and citations in sync. Thanks to the
            seamless integration between CiteDrive and Overleaf, you can now
            effortlessly add in-text citations to your paper without ever
            leaving your Overleaf project.
          </LargeP>
        </TextWrapper>

        <PromoVideo />

        <Feedback
          feedback1={doc.data.quotes[0].quote}
          feedback2={doc.data.quotes[1].quote}
        />

        <CallToActionPanel
          heading={doc.data.call_to_action_panel.map(
            (ele) => ele.call_to_action_heading.text
          )}
          subheading={doc.data.call_to_action_panel.map(
            (ele) => ele.call_to_action_subheading.text
          )}
          label="Create my free account"
        />

        <TextWrapper>
          <PrismicRichText
            field={doc.data.getting_started.richText}
            components={{
              heading2: ({ children, key }) => (
                <H2 key={key} style={{ textAlign: "center" }}>
                  {children}
                </H2>
              ),
              paragraph: ({ children, key }) => (
                <LargeP key={key} style={{ margin: "2rem 0" }}>
                  {children}
                </LargeP>
              ),
              oList: ({ children, key }) => <OL key={key}>{children}</OL>,
              oListItem: ({ children, key }) => (
                <LI number={key} key={key}>
                  {children}
                </LI>
              ),
              hyperlink: ({ node, children }) => (
                <ExternalBlogPostLink children={children} node={node} />
              ),
            }}
          />
        </TextWrapper>

        <TextWrapper>
          <PrismicRichText
            field={doc.data.find_out_more.richText}
            components={{
              heading2: ({ children, key }) => (
                <H2 key={key} style={{ textAlign: "center" }}>
                  {children}
                </H2>
              ),
              paragraph: ({ children, key }) => (
                <LargeP key={key}>{children}</LargeP>
              ),
              hyperlink: ({ node, children, key }) => (
                <ExternalBlogPostLink
                  key={key}
                  children={children}
                  node={node}
                />
              ),
            }}
          />
        </TextWrapper>

        <TextWrapper style={{ textAlign: "left" }}>
          <PrismicRichText
            field={doc.data.overleaf_story.richText}
            components={{
              heading2: ({ children, key }) => (
                <H2 key={key} style={{ textAlign: "center" }}>
                  {children}
                </H2>
              ),
              paragraph: ({ children, key }) => (
                <LargeP key={key} style={{ maxWidth: "100%" }}>
                  {children}
                </LargeP>
              ),
              hyperlink: ({ node, children, key }) => (
                <ExternalBlogPostLink
                  key={key}
                  children={children}
                  node={node}
                />
              ),
            }}
          />
        </TextWrapper>

        <TextWrapper style={{ textAlign: "left" }}>
          <PrismicRichText
            field={doc.data.citedrive_story.richText}
            components={{
              heading2: ({ children, key }) => (
                <H2 key={key} style={{ textAlign: "center" }}>
                  {children}
                </H2>
              ),
              paragraph: ({ children, key }) => (
                <LargeP key={key} style={{ maxWidth: "100%" }}>
                  {children}
                </LargeP>
              ),
            }}
          />
        </TextWrapper>
        <CallToActionPanel
          heading={doc.data.call_to_action_panel.map(
            (ele) => ele.call_to_action_heading.text
          )}
          subheading={doc.data.call_to_action_panel.map(
            (ele) => ele.call_to_action_subheading.text
          )}
          label="Create my free account"
        />
      </Container>
    </Layout>
  );
};

export default Overleaf;
export const query = graphql`
  query OverleafQuery($id: String, $lang: String) {
    prismicOverleaf(id: { eq: $id }, lang: { eq: $lang }) {
      url
      type
      lang
      last_publication_date
      alternate_languages {
        uid
        type
        raw
        lang
        id
      }
      data {
        body {
          ... on PrismicOverleafDataBodyMetaTags {
            id
            primary {
              seo_title
              meta_description
            }
          }
        }
        title {
          richText
        }
        subtitle {
          text
        }
        eyebrow {
          text
        }
        description {
          richText
        }
        body1 {
          ... on PrismicOverleafDataBody1Feature {
            id
            items {
              feature_name {
                text
              }
              feature_description {
                text
              }
            }
          }
        }
        quotes {
          quote
        }
        call_to_action_panel {
          call_to_action_heading {
            text
          }
          call_to_action_subheading {
            text
          }
          button_label
        }
        getting_started {
          richText
        }
        find_out_more {
          richText
        }
        overleaf_story {
          richText
        }
        citedrive_story {
          richText
        }
      }
    }
  }
`;
