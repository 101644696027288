import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import styled from "styled-components";
import OverleafHeroImage from "../../assets/overleafHeroImage";
import { CallToActionButtonA } from "../atoms/button";
import { H1 } from "../atoms/headings";
import { HeroSubtitle } from "../atoms/paragraph";

// styles

const Wrapper = styled.section``;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

const Item = styled.div`
  padding: 1rem;
  flex-basis: min(calc(640px - 2.5rem), 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 680px) {
    padding: 0rem;
  }
`;

const Eyebrow = styled.div`
  font-family: acumin-pro-condensed, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  color: #333333;
  margin-bottom: 0.75rem;
`;

interface OverleafHeroProps {
  eyebrow: string;
  title: any;
  subtitle: string;
}

const OverleafHero: React.FunctionComponent<OverleafHeroProps> = ({
  eyebrow,
  title,
  subtitle,
}) => {
  return (
    <Wrapper>
      <Container>
        <Item>
          <OverleafHeroImage style={{ width: "450px" }} />
        </Item>
        <Item>
          <div>
            <Eyebrow>{eyebrow}</Eyebrow>
            {/* <PrismicRichText 
            field={title}
            components={{
              heading1: ({ children }) => <H1>{children}</H1>,
            }}
            />  */}
            <H1>
              The missing piece: <br />
              CiteDrive brings reference management to Overleaf
            </H1>
            <HeroSubtitle>{subtitle}</HeroSubtitle>
            <CallToActionButtonA
              style={{ width: "300px" }}
              href="https://app.citedrive.com/sign-up/"
            >
              Create my account
            </CallToActionButtonA>
          </div>
        </Item>
      </Container>
    </Wrapper>
  );
};

export default OverleafHero;
