import * as React from "react"

const OverleafHeroImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
    <path d="M773.9 435.92v-31.23a47 47 0 0 0-93.93 0v31.23h-93.9V342h-31.23a47 47 0 0 1-45.42-35.94 46.2 46.2 0 0 1-1.31-11 47 47 0 0 1 46.73-47h31.23v-93.9H30.53a8 8 0 0 0-8 8v829.3a8 8 0 0 0 8 8h829.3a8 8 0 0 0 8-8V435.92Z" />
    <path
      d="M303.31 435.08v.84h-.89c.29-.28.58-.57.89-.84ZM585.06 434.33h.02v.02h-.02z"
      style={{
        fill: "#138a07",
      }}
    />
    <path d="M999.68 30.09v273.78h-93.91v-31.23a47 47 0 0 0-93.93 0v31.23h-93.92V210h-31.23a47 47 0 0 1 0-93.93h31.23V22.11H991.7a8 8 0 0 1 7.98 7.98Z" />
    <path
      d="M360 273.07a47 47 0 0 1-46.73 47h-31.19v93.91h-93.92v-31.27a47 47 0 0 0-93.93 0v31.23H.32V140.17a8 8 0 0 1 8-8h273.76v93.93h31.23a46.37 46.37 0 0 1 19.86 4.52A46.92 46.92 0 0 1 360 273.07Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M333.17 230.62c-37.84 30.16-62 72.21-62.16 129.59a443.07 443.07 0 0 0-68.41 53.73h78.58q.44-.44.9-.84V320h31.23a47 47 0 0 0 19.86-89.41Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M563.84 132.18v93.92h-31.23a47 47 0 0 0 0 93.93h31.23V414h-93.92v-31.27a47 47 0 0 0-93.93 0V414h-93.91v-94h31.23a47 47 0 0 0 19.86-89.41 46.37 46.37 0 0 0-19.86-4.52h-31.23v-93.89Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M333.17 230.62A47 47 0 0 1 313.31 320h-31.23v93.1c57.6-54.32 130.7-97 205.1-129a47 47 0 0 1 45.43-58h31.23v-62.5c-84.1 2.85-173.16 21.19-230.67 67.02ZM532.61 320a46.72 46.72 0 0 1-31.87-12.7c-19.08 10.8-38.2 22.09-56.56 33.73a47 47 0 0 1 25.74 41.67V395c37.08 5 67.65.47 93.92-10.33V320ZM359 399.7c-6.61 4.18-13.41 9-20.32 14.26H376v-14.52a126.32 126.32 0 0 0-17 .26Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M977.82 8.54v273.78h-93.91v-31.23a47 47 0 0 0-93.94 0v31.23h-93.91V188.4h-31.23a47 47 0 0 1-45.42-35.94 46.13 46.13 0 0 1-1.31-11 47 47 0 0 1 46.73-47h31.23V.56h273.78a8 8 0 0 1 7.98 7.98Z"
      style={{
        fill: "#fac188",
      }}
    />
    <path
      d="M696.06 32v62.47h-31.23a47 47 0 0 0-46.73 47 46.13 46.13 0 0 0 1.31 11 1023.43 1023.43 0 0 1 155.67-52.32c-36 19.16-88.92 45.42-142.12 75.57a46.68 46.68 0 0 0 31.87 12.69h31.23v64.65c86.73-35.7 126.34-140 197-196.88C854 40.89 777.35 29.2 696.06 32Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M563.83 413.94v93.9H532.6a47 47 0 0 0 0 93.93h31.23v93.93h-93.92v31.23a47 47 0 0 1-93.93 0V695.7h-93.91v-93.93h31.23a47 47 0 0 0 0-93.93h-31.23v-93.9H376v-31.23A47 47 0 0 1 423 336a47 47 0 0 1 47 46.73v31.23Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M444.19 341.06C426.9 352 410.28 363.28 395 374.71c27.68 10.73 52.51 17.25 74.94 20.3v-12.3a46.92 46.92 0 0 0-25.75-41.65Zm11.75 76.63c-16.78-6.29-49.72-16.79-80-18.25a126.08 126.08 0 0 0-17 .26c-6.61 4.18-13.41 9-20.31 14.24a410.07 410.07 0 0 0-56.6 53.49v40.41h8.67c31.22-13.16 65.3-17.37 97.3-12.18a179.94 179.94 0 0 1 98.64 50.88 47 47 0 0 1 46-38.7h31.23v-15.28a272.34 272.34 0 0 0-107.93-74.87Zm76.66 184.08a47 47 0 0 1-8.93-.89 179.42 179.42 0 0 1 15.23 72.71 183.33 183.33 0 0 1-1.34 22.11h26.27v-93.93Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M360 554.83a47 47 0 0 1-46.73 47h-31.19v93.87h-93.92v31.23a47 47 0 0 1-93.93 0V695.7H.32V413.94h93.91v-31.23a47 47 0 0 1 93.93 0v31.23h93.92v93.92h31.23A47 47 0 0 1 360 554.83Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M282.08 507.86v-40.43c-24.4 27.72-46.16 59.44-60 91.2a180.43 180.43 0 0 1 68.68-50.77Zm-112 102.76c12.61-77.5 54.23-142.79 111.12-196.68h-78.6C129.92 483.7 85.08 575.83 85.08 673.09q0 11.43.93 22.61h8.22v31.23a47 47 0 0 0 92.7 10.48c-19.88-41.64-23.85-84.59-16.87-126.79Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M845.59 413.94V695.7h-93.92v31.23a47 47 0 0 1-93.93 0V695.7h-93.91v-93.91H532.6a47 47 0 0 1 0-93.93h31.23v-93.92h93.91v-31.23a47 47 0 0 1 93.93 0v31.23Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M563.83 492.56v15.3H532.6a47 47 0 0 0-46 38.68 179.21 179.21 0 0 1 37.07 54.35 47 47 0 0 0 8.93.9h31.23v93.91h67.11c.6-7.45.92-15 .92-22.61a272.69 272.69 0 0 0-68.03-180.53Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M360 836.59a46.93 46.93 0 0 1-46.73 47h-31.19v93.91H8.3a8 8 0 0 1-8-8V695.7h93.93v31.23a47 47 0 0 0 92.7 10.46 45.78 45.78 0 0 0 1.23-10.46V695.7h93.92v93.92h31.23A47 47 0 0 1 360 836.59Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M222 790.54a240.11 240.11 0 0 1-35.11-53.15 47 47 0 0 1-92.7-10.46V695.7H86c9.38 114.4 89.24 208.89 196.06 239.93v-52.08h31.23a47 47 0 0 0 43.59-30A179.72 179.72 0 0 1 222 790.54Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M485.86 836.56a47 47 0 0 0 46.74 47h31.23v93.93H282.07v-93.96h31.23a47 47 0 0 0 0-93.93h-31.23v-93.9H376v31.23a47 47 0 0 0 93.93 0V695.7h93.92v93.9H532.6a47 47 0 0 0-46.74 46.96Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M532.6 789.6h31.23v-93.9h-26.27A179.82 179.82 0 0 1 359 853.5h-2.09a47 47 0 0 1-43.59 30h-31.25v52.08a273.61 273.61 0 0 0 250.86-52.08h-.33a47 47 0 0 1 0-93.93Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M845.59 695.7v273.78a8 8 0 0 1-8 8H563.83v-93.93H532.6a47 47 0 0 1 0-93.93h31.23V695.7h93.91v31.23a47 47 0 0 0 93.93 0V695.7Z"
      style={{
        fill: "#138a07",
      }}
    />
    <path
      d="M563.83 789.62H532.6a47 47 0 0 0 0 93.93h.33a272.92 272.92 0 0 0 98-187.85h-67.1Z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)

export default OverleafHeroImage