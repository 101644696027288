import * as React from "react"

function useIntersectionObserver(options: any) {
  const [visible, setVisible] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries
      entries.forEach(entry => {
        setVisible(entry.isIntersecting)
      })
    }, options)
    if (ref.current) observer.observe(ref.current)
    // this function is a cleanup function that only works when used with useEffect.
    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [ref, options])

  return {
    visible,
    ref,
  }
  
}

export default useIntersectionObserver
